<template>
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <p id="addExistingModal" v-bind="attrs" v-on="on" @click="loadData"></p>
    </template>

    <v-card style=" padding:20px; position: relative;" class="text-center">
      <v-row>
        <v-col cols="12" style="font-weight:bold;">
          <div class="d-flex" style="width:80%;">
            <v-text-field
              dense
              v-model="paramAPI.keyword"
              @keyup.enter="searchEnter"
              label="Search here"
              type="search"
              outlined
              append-icon="mdi-magnify"
              style="position: relative; top: 15px; width: 100px; font-weight:normal;"
            ></v-text-field>

            <v-autocomplete
              label="- Plant -"
              v-model="paramAPI.company_id"
              :items="dropdownCompany"
              @change="changeCompany"
              :disabled="isDisableCompanyDropdown"
              item-text="name"
              item-value="id"
              return-id
              outlined
              dense
              style="margin: 0 0 0 10px; height:40px; width: 100px; position: relative; top: 15px; font-weight:normal;"
              clearable
            ></v-autocomplete>
          </div>
          <v-btn
            text
            icon
            style="position:absolute;top:0; right:0;"
            color="black lighten-2"
            @click="cancel"
          >
            <v-icon> mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col md="12" style="padding: 0">
          <v-simple-table fixed-header dense height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    No. Request
                  </th>
                  <th class="text-left">
                    PIC
                  </th>
                  <th class="text-left">
                    Department
                  </th>
                  <th class="text-left">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style="cursor:pointer;"
                  v-for="(item, index) in result"
                  :key="index"
                  class="text-left"
                  @click="rowClick(item)"
                >
                  <td>{{ item.request_no }}</td>
                  <td>{{ item.employee.name }}</td>
                  <td>{{ item.department.name }}</td>
                  <td :style="`color: ${colorStatus(item.status)}`">
                    {{ item.status.name }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <div
        v-if="loading"
        class="d-flex justify-center align-center"
        style="position:absolute; top:0; left:0; right:0; bottom:0; z-index:5; background:rgba(255,255,255,0.6);"
      >
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
          class="d-flex justify-center align-center"
        >
          <p
            class="d-flex justify-center align-center"
            style="font-size:12px; font-weight: bold; margin:auto;"
          >
            Please wait
          </p>
        </v-progress-circular>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  props: ['proc', 'selected', 'eSanqua'],
  data() {
    return {
      dialog: false,
      input: '',
      loading: false,
      paramAPI: {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        status: '',
        length: 0,
        sortBy: 'request_no',
        sortType: 'desc',
        itemsPerPage: 10,
        company_id: null,
        department_id: null,
        category_item: null,
        project_id: null,
        request_date_start: '',
        request_date_end: ''
      },
      result: [],
      isDisableCompanyDropdown: false,
      dropdownCompany: []
    }
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    async changeCompany(p) {
      this.paramAPI.company_id = p

      this.initDataTable()
    },
    loadData() {
      this.initDataTable()
      this.getDropdownCompany()
    },
    initDataTable() {
      let url = `${this.proc}fpb/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&order_by=${this.paramAPI.sortBy}&order_type=${
        this.paramAPI.sortType
      }&status=${this.paramAPI.status}`

      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      if (this.paramAPI.department_id !== null) {
        url = url + `&department_id=${this.paramAPI.department_id}`
      } else {
        url = url + '&department_id='
      }
      if (this.paramAPI.category_item !== null) {
        url = url + `&category_item=${this.paramAPI.category_item}`
      }
      if (this.paramAPI.request_date_start !== null) {
        url = url + `&request_date_start=${this.paramAPI.request_date_start}`
      }
      if (this.paramAPI.request_date_end !== null) {
        url = url + `&request_date_end=${this.paramAPI.request_date_end}`
      }
      if (this.paramAPI.project_id !== null) {
        url = url + `&project_id=${this.paramAPI.project_id}`
      }
      this.url = url
      axios
        .get(
          url
          // `${this.proc}fpb/list?keyword=${this.paramAPI.keyword}&offset=${this
          //   .paramAPI.offset * this.paramAPI.limit}&limit=${
          //   this.paramAPI.limit
          // }&order_by=${this.paramAPI.sortBy}&order_type=${
          //   this.paramAPI.sortType
          // }&status=${this.paramAPI.status}`
        )
        .then(res => {
          console.log(res)
          this.result = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    // submit() {
    //   this.dialog = false
    //   this.$emit('submit', this.input)
    // },
    cancel() {
      this.dropdownCompany = []
      this.paramAPI.company_id = null
      this.isDisableCompanyDropdown = false
      this.paramAPI.keyword = ''
      setTimeout(() => {
        this.dialog = false
      }, 200)
    },
    rowClick(pItem) {
      console.log(pItem)
      this.showMsgDialog(
        'question',
        `Tambahkan item pada nomor FPB ${pItem.request_no}`,
        true
      ).then(res => {
        console.log(this.selected)
        if (res.isConfirmed) {
          this.loading = true
          const form = {
            act: 'add_batch',
            request_id: pItem.id,
            items: []
          }
          for (let i = 0; i < this.selected.length; i++) {
            console.log(this.selected[i])
            const obj = {
              product_id: Number(this.selected[i].product.id),
              product_name: this.selected[i].product.name,
              product_code: this.selected[i].product.code,
              qty: this.selected[i].qty,
              budget_price_per_unit: this.selected[i].budget,
              quotation_price_per_unit: 0,
              current_stock: 0,
              uom_id: this.selected[i].uom_id,
              uom_name: this.selected[i].uom_name,
              vendor_id: Number(this.selected[i].vendor.clear_id),
              vendor_name: this.selected[i].vendor.name,
              vendor_code: this.selected[i].vendor.code,
              has_budget: 0,
              estimate_date_use: null,
              description: this.selected[i].reason
            }
            form.items.push(obj)
          }
          console.log(form)
          axios
            .post(`${this.proc}fpb/save_batch_detail`, form)
            .then(res => {
              console.log(res.data)
              this.loading = false
              if (res.data.status_code === '00') {
                this.dialog = false
                // this.$store.commit('setFpbId', pItem.id)
                // this.$router.push('/e-catalogue/cart/fpb')
                // this.$store.state.cart.savedData = []

                for (let i = 0; i < this.selected.length; i++) {
                  const ci = this.$store.state.cart.savedData.indexOf(
                    this.selected[i]
                  )
                  this.$store.state.cart.savedData.splice(ci, 1)
                }
                // this.$store.state.cart.savedData = []
                this.$store.commit('setFpbId', pItem.id)
                this.showMsgDialog('success', res.data.status_msg, false)
                setTimeout(() => {
                  this.$router.push('/e-catalogue/cart/fpb')
                }, 300)
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  let xFlag = false
                  if (res.data.error_msg !== undefined) {
                    if (res.data.error_msg !== null) {
                      if (res.data.error_msg.length > 0) {
                        this.showMsgDialog(
                          'error',
                          `${res.data.error_msg[0].msg} (${res.data.error_msg[0].value})`,
                          false
                        )
                      } else {
                        console.log('HERE 3')
                        xFlag = true
                      }
                    } else {
                      console.log('HERE 2')
                      xFlag = true
                    }
                  } else {
                    console.log('HERE 1')
                    xFlag = true
                  }

                  if (xFlag) {
                    this.showMsgDialog('error', res.data.status_msg, false)
                  }
                } else {
                  this.showMsgDialog('error', res.data.status_msg, false)
                }
              }
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        }
      })
    },
    searchEnter() {
      this.initDataTable()
    },
    async getDropdownCompany() {
      const level = this.getUserProfile.level.find(
        ({ id }) =>
          id === '3' || id === '14' || id === '23' || id === '27' || id === '41'
      )
      if (level !== undefined) {
        if (level.user_user_level !== null) {
          if (
            level.user_user_level.allowed_company === null ||
            level.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdownCompany = level.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
      } else {
        this.dropdownCompanyApi()
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = true
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then(res => {
          this.dropdownCompany = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    colorStatus(val) {
      switch (val.id) {
        case -1:
          return 'red'
        case 0:
          return '#1976d2'
        case 1:
          return 'orange'
        case 2:
          return 'green'
        case 3:
          return '#4CAF50'
        case 4:
          return 'red'
        case 5:
          return 'orange'
        // return '#1976d2'
      }
    }
  }
}
</script>
<style></style>
